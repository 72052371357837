import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { animationVariants } from '@/utils/framerMotion.utils';

const SlideModalComponent = ({
	isOpen,
	onClose,
	title = 'Slide',
	slideMode = 'fromRight',
	children
}) => {
	useEffect(() => {
		if (isOpen) {
			document.body.classList.add('no-scroll');
		} else {
			document.body.classList.remove('no-scroll');
		}

		return () => {
			document.body.classList.remove('no-scroll');
		};
	}, [isOpen]);

	const onCloseHandler = () => {
		if (onClose) {
			onClose();
		}
	};

	return (
		<React.Fragment>
			{/* {isOpen && (
				<div
					className={`
								fixed inset-0 w-full min-h-screen z-300 transition-opacity duration-300 ease-in-out
							`}
					onClick={onCloseHandler}></div>
			)} */}
			<AnimatePresence>
				{isOpen && (
					<React.Fragment>
						<div className="flex justify-center items-center fixed inset-0 w-full h-full xl:bg-black/70 z-500">
							<div className="w-full max-w-7xl h-full xl:h-5/6 overflow-y-auto xl:rounded-lg">
								<motion.div
									initial="closed"
									animate="open"
									exit="closed"
									variants={animationVariants[slideMode]}
									transition={{ duration: 0.5, ease: 'easeInOut' }}
									onAnimationComplete={() => !isOpen && onClose()}
									className="bg-container-bg-primary h-full w-full overflow-y-auto">
									<div className="flex justify-between items-center p-2 h-14 text-text-color-primary">
										<h3 className="text-lg font-semibold gradient-text-color">{title}</h3>
										<CloseOutlinedIcon
											className="cursor-pointer text-container-color-primary"
											onClick={onCloseHandler}
										/>
									</div>
									<div className="flex w-full justify-center">
										<div className="p-2 w-full container-body">{children}</div>
									</div>
								</motion.div>
							</div>
						</div>
					</React.Fragment>
				)}
			</AnimatePresence>
		</React.Fragment>
	);
};

export default SlideModalComponent;
