const { create } = require('zustand');

const useGameSearchComponentStore = create((set) => ({
	openSearchGamePage: false,
	onSearch: '',
	setOpenSearchGamePage: () => {
		set({ openSearchGamePage: true });
	},
	setCloseSearchGamePage: () => {
		set({ openSearchGamePage: false });
	},
	setOnSearch: (onSearch) => {
		set({ onSearch: onSearch });
	},
	setClearOnSearch: () => {
		set({
			onSearch: ''
		});
	}
}));

export default useGameSearchComponentStore;
