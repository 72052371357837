import LabelCardImage2 from '../../../atom/LabelCard/LabelCardImage2';
import useGameActionHook from '../../../../../hooks/useGameActionHook.hook';
import { customImageLoader } from '../../../../helpers/custom-image-loader';
import Image from 'next/image';
import { cn } from '../../../../../utils/cn';

const CardGame = ({ game, cardType, className }) => {
	const { gameRouterPush } = useGameActionHook();

	const handleGameClick = (mode) => {
		// console.log('ke clikc : ', game);
		gameRouterPush(mode, game);
	};

	// Choose the component based on cardType
	switch (cardType) {
		case 'hot':
			return <HotCard game={game} onClick={handleGameClick} className={className} />;
		case 'loading':
			return <LoadingCard />;
		default:
			return <StandardCard game={game} onClick={handleGameClick} className={className} />;
	}
};

const StandardCard = ({ game, onClick, className }) => {
	// let darkVibrant = game?.co?.[3];
	return (
		<div>
			<div
				className={cn(
					'group relative aspect-[3/4] overflow-hidden hover:bg-opacity-80 transition-all duration-300 ease-in-out cursor-pointer w-full',
					className
				)}
				onMouseEnter={(e) => e.stopPropagation()}
				// onClick={() => !game?.da && onClick(0)}
				onClick={() => onClick(0)}>
				<div className="flex flex-col">
					<GameImage game={game} />
					<GameTitle game={game} />
				</div>
				{/* {game?.da && <GameOverlay game={game} gameRouterPush={onClick} darkVibrant={darkVibrant} />} */}
			</div>
		</div>
	);
};

const HotCard = ({ game, onClick, className }) => {
	return (
		<div
			className={cn(
				'group relative aspect-[3/4] overflow-hidden hover:bg-opacity-80 transition-all duration-300 ease-in-out cursor-pointer',
				className
			)}
			onMouseEnter={(e) => e.stopPropagation()}
			onClick={() => !game?.da && onClick(0)}>
			<div className="flex-col aspect-[3/4] bg-inherit">
				<div className="relative flex-1 aspect-square">
					<div className="flex w-full justify-between absolute top-0">
						<LabelCardImage2 labelName={'trendingLabel'} className={'z-10 w-16 object-contain'} />
						<LabelCardImage2 labelName={'newLabel'} className={'z-10 w-8'} />
					</div>
					<Image
						id={`${game?.pi}-${game?.pn}`}
						src={game?.img || '/images/games/404.jpg'}
						loader={customImageLoader}
						layout="fill"
						objectFit="cover"
						alt={game?.gn}
						loading="lazy"
					/>
				</div>
				<GameTitle game={game} />
			</div>
			{game?.da && <GameOverlay game={game} gameRouterPush={onClick} />}
		</div>
	);
};

const LoadingCard = () => (
	<div className="animate-pulse flex flex-col aspect-[3/4] bg-inherit gap-1">
		<div className="aspect-square bg-slate-500 rounded-lg"></div>
		<div className="flex w-full flex-col items-center justify-center gap-1">
			<div className="w-full h-2 bg-slate-500 rounded-full"></div>
			<div className="w-full h-2 bg-slate-500 rounded-full"></div>
			<div className="w-full h-2 bg-slate-500 rounded-full"></div>
		</div>
	</div>
);

// Reusable components
const GameImage = ({ game }) => (
	<div className="relative aspect-square w-full h-full">
		<Image
			id={`${game?.pi}-${game?.pn}`}
			src={game?.img || '/images/games/404.jpg'}
			loader={customImageLoader}
			layout="fill"
			objectFit="cover"
			alt={game?.gn}
			loading="lazy"
		/>
	</div>
);

const GameTitle = ({ game }) => (
	<div className="flex-1 flex items-center justify-center text-center text-container-color-primary p-1">
		<p className="text-xs break-words font-semibold leading-none line-clamp-2 text-ellipsis">
			{game?.gn}
		</p>
	</div>
);

const GameOverlay = ({ game, gameRouterPush, darkVibrant }) => (
	<div className="aspect-square">
		<OverlayButton
			position="top-0"
			background={darkVibrant || '#333'}
			onClick={() => gameRouterPush(0, game)}
			label="Play"
		/>
		<OverlayButton
			position="bottom-0"
			buttonClassName="bg-black/50"
			background={darkVibrant || '#333'}
			onClick={() => gameRouterPush(1, game)}
			label={<span>Demo</span>}
		/>
	</div>
);

const OverlayButton = ({ position, background, onClick, label, buttonClassName }) => (
	<div
		className={cn(
			'absolute left-0 w-full h-1/2 flex items-center justify-center text-lg transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100',
			position
		)}
		style={{ background: background }}>
		<button
			className={cn('w-full h-full text-container-color-primary', buttonClassName)}
			onClick={onClick}>
			{label}
		</button>
	</div>
);

// const CardGame = ({ game, cardType, className }) => {
// 	let darkVibrant = game?.co?.[3];
// 	const { gameRouterPush } = useGameActionHook();

// 	if (cardType === 'loading') {
// 		return (
// 			<>
// 				<div className="animate-pulse flex flex-col aspect-[3/4] bg-inherit gap-1">
// 					<div className="aspect-square bg-slate-500 rounded-lg"></div>

// 					<div className="flex w-full flex-col items-center justify-center gap-1">
// 						<div className="w-full h-2 bg-slate-500 rounded-full"></div>
// 						<div className="w-full h-2 bg-slate-500 rounded-full"></div>
// 						<div className="w-full h-2 bg-slate-500 rounded-full"></div>
// 					</div>
// 				</div>
// 			</>
// 		);
// 	}

// 	if (cardType === 'hot') {
// 		return (
// 			<>
// 				<div
// 					className="group relative aspect-[3/4] overflow-hidden hover:bg-opacity-80 transition-all duration-300 ease-in-out cursor-pointer"
// 					onMouseEnter={(e) => e.stopPropagation()}
// 					onClick={() => {
// 						if (!game?.da) {
// 							gameRouterPush(false);
// 						}
// 					}}>
// 					<div className="flex-col aspect-[3/4] bg-inherit">
// 						<div className="relative flex-1 aspect-square">
// 							<div className="flex w-full justify-between absolute top-0">
// 								<LabelCardImage2
// 									labelName={'trendingLabel'}
// 									className={'z-10 w-16 object-contain'}
// 								/>
// 								<LabelCardImage2 labelName={'newLabel'} className={'z-10 w-8'} />
// 							</div>
// 							<img
// 								id={`${game?.img}`}
// 								src={game?.img ? game?.img : '/images/games/404.jpeg'}
// 								alt={game?.gameName}
// 								className="w-full h-full object-cover"
// 								loading="lazy"
// 							/>
// 						</div>
// 						<div className="flex flex-col items-center justify-center p-1">
// 							<p className="flex-1 w-full text-xs text-center break-words line-clamp-2 font-semibold leading-none text-container-color-primary">
// 								{game?.pn}
// 							</p>
// 						</div>
// 					</div>

// 					{game?.da && (
// 						<>
// 							<div className="aspect-square">
// 								<div
// 									className="absolute top-0 left-0 w-full h-1/2 flex items-center justify-center text-lg transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100"
// 									style={{ background: darkVibrant || '#333333' }}>
// 									<button
// 										className="w-full h-full text-container-color-primary"
// 										onClick={() => {
// 											gameRouterPush(false);
// 										}}>
// 										Play
// 									</button>
// 								</div>

// 								<div
// 									className="absolute bottom-0 left-0 w-full h-1/2 flex items-center justify-center text-lg transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100"
// 									style={{ background: darkVibrant || '#333333' }}>
// 									<button
// 										className="w-full h-full text-container-color-primary bg-black/50"
// 										onClick={() => {
// 											gameRouterPush(true);
// 										}}>
// 										<span>Demo</span>
// 									</button>
// 								</div>
// 							</div>
// 						</>
// 					)}
// 				</div>
// 			</>
// 		);
// 	}

// 	return (
// 		<>
// 			<div
// 				className={cn(
// 					'group relative aspect-[3/4] overflow-hidden hover:bg-opacity-80 transition-all duration-300 ease-in-out cursor-pointer w-52',
// 					className
// 				)}
// 				onMouseEnter={(e) => e.stopPropagation()}
// 				onClick={() => {
// 					if (!game?.da) {
// 						gameRouterPush(0, game);
// 						// onClickGame(false);
// 					}
// 				}}>
// 				<div className="w-full h-full flex flex-col">
// 					<div className="relative aspect-square">
// 						<Image
// 							id={`${game?.pi}-${game?.pn}`}
// 							src={game?.img ? game?.img : '/images/games/404.jpg'}
// 							loader={customImageLoader}
// 							layout="fill"
// 							objectFit="contain"
// 							alt={game?.gn}
// 							// className="w-full object-cover aspect-square"
// 							loading="lazy"
// 						/>
// 					</div>

// 					<div className="flex-1 flex items-center justify-center text-center text-container-color-primary p-1">
// 						<p className="text-xs break-words font-semibold leading-none line-clamp-2 text-ellipsis">
// 							{game?.gn}
// 						</p>
// 					</div>
// 				</div>

// 				{game?.da && (
// 					<>
// 						<div className="aspect-square">
// 							<div
// 								className="absolute top-0 left-0 w-full h-1/2 flex items-center justify-center text-lg transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100"
// 								style={{ background: darkVibrant || '#333333' }}>
// 								<button
// 									className="w-full h-full text-container-color-primary"
// 									onClick={() => {
// 										gameRouterPush(0, game);
// 										// onClickGame(false);
// 									}}>
// 									Play
// 								</button>
// 							</div>

// 							<div
// 								className="absolute bottom-0 left-0 w-full h-1/2 flex items-center justify-center text-lg transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100"
// 								style={{ background: darkVibrant || '#333333' }}>
// 								<button
// 									className="w-full h-full text-container-color-primary bg-black/50"
// 									onClick={() => {
// 										gameRouterPush(1, game);
// 										// onClickGame(true);
// 									}}>
// 									<span>Demo</span>
// 								</button>
// 							</div>
// 						</div>
// 					</>
// 				)}
// 			</div>
// 		</>
// 	);
// };

export default CardGame;
