export const animationVariants = {
	fromRight: {
		open: { x: 0, opacity: 1 },
		closed: { x: '100%', opacity: 0 }
	},
	fromLeft: {
		open: { x: 0, opacity: 1 },
		closed: { x: '-100%', opacity: 0 }
	},
	fromTop: {
		open: { y: 0, opacity: 1 },
		closed: { y: '-100%', opacity: 0 }
	},
	fromBottom: {
		open: { y: 0, opacity: 1 },
		closed: { y: '100%', opacity: 0 }
	},
	fadeAndTransform: {
		hidden: { opacity: 0, y: '-100%' }, // Initial hidden state
		visible: { opacity: 1, y: 0 } // Visible state with transform
	},
	easeInOut: {
		hidden: { opacity: 0 },
		visible: { opacity: 1 }
	}
};
