const { create } = require('zustand');

const useNavbarStore = create((set) => ({
	openNavbar: true,
	setToggleNavbar: (openNavbar) => {
		set({ openNavbar: openNavbar });
	},
	setOpenNavbar: () => {
		set({ openNavbar: true });
	},
	setCloseNavbar: () => {
		set({ openNavbar: false });
	}
}));

export default useNavbarStore;
