import { useEffect, useState } from 'react';
import SlideModalComponent from '@/features/modal/SlideModal';
import SearchComponent from '../../../../atom/SearchComponent/SearchComponent';
import useGameSearchComponentStore from '../../stores/useGameSearchComponentStore.store';
import CardGame from '../../../Card/CardGame';
import useNavbarStore from '@/context/store/useNavbarStore.store';
import useGameStore from '@/context/store/useGameStore.store';
import { useTranslation } from 'react-i18next';

const initGames = { totalGames: 0, gameData: [] };

const GameSearchPageComponent2 = () => {
	const { t } = useTranslation();
	const { allGameData } = useGameStore((state) => ({
		allGameData: state.allGameData
	}));
	const { openSearchGamePage, onSearch, setCloseSearchGamePage, setOnSearch, setClearOnSearch } =
		useGameSearchComponentStore((state) => ({
			openSearchGamePage: state.openSearchGamePage,
			onSearch: state.onSearch,
			setCloseSearchGamePage: state.setCloseSearchGamePage,
			setOnSearch: state.setOnSearch,
			setClearOnSearch: state.setClearOnSearch
		}));
	const { setOpenNavbar } = useNavbarStore((state) => ({
		setOpenNavbar: state.setOpenNavbar
	}));

	const [games, setGames] = useState(initGames);
	const [totalGames, setTotalGames] = useState(0);
	const [displayCount, setDisplayCount] = useState(100);
	const itemsToDisplay = games.gameData?.slice(0, displayCount);

	useEffect(() => {
		setGames({
			...games,
			gameData: allGameData?.body
		});
		setTotalGames(allGameData?.total);
	}, [allGameData]);

	const changeGamesPayload = () => {
		setDisplayCount((prevCount) => prevCount + 100);
	};

	const searchGames = () => {
		const searchTerm = onSearch.toLowerCase();
		const newSearchGameData = allGameData?.body?.filter(
			(game) =>
				game.gn.toLowerCase().includes(searchTerm) ||
				game.pi.toLowerCase().includes(searchTerm) ||
				game.cn.toLowerCase().includes(searchTerm) ||
				game.pn.toLowerCase().includes(searchTerm)
		);
		// Reset display count when new search is made
		setDisplayCount(100);
		setTotalGames(newSearchGameData?.length);
		setGames({
			...games,
			gameData: newSearchGameData
		});
	};

	const onCloseSearchGamePageComponent = () => {
		setGames({
			...games,
			gameData: allGameData?.body
		});
		setClearOnSearch();
		setOpenNavbar();
		setCloseSearchGamePage();
	};

	const getSearchGame = () => {
		if (onSearch && onSearch !== '') {
			searchGames();
		} else {
			// Reset display count when search is cleared
			setDisplayCount(100);
			setGames({
				...games,
				gameData: allGameData?.body
			});
		}
	};

	return (
		<SlideModalComponent
			isOpen={openSearchGamePage}
			title="Cari Game"
			onClose={onCloseSearchGamePageComponent}
			slideMode="fromBottom">
			<div className="space-y-section">
				<SearchComponent
					gradientBorder
					placeholder={t('text.searchFavoriteGame')}
					value={onSearch}
					onChange={setOnSearch}
					onSearch={getSearchGame}
				/>

				<div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 gap-2">
					{itemsToDisplay.map((game, index) => (
						<CardGame key={`${game?.gpId}-${game?.gpGameId}-${index}`} game={game} />
					))}
				</div>
				{totalGames > displayCount && (
					<>
						<div
							className="text-center cursor-pointer text-container-color-primary"
							onClick={changeGamesPayload}>
							Click to load more games
						</div>
					</>
				)}
			</div>
		</SlideModalComponent>
	);
};

export default GameSearchPageComponent2;
